.HeaderDesktop, .MarketHeaderDesktop {
  .DropRegisterContainer {
    background: var(--gray3);
    border-radius: 10px;
    min-width: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 8px 6px;

    .DropDate {
      font-size: 14px;
      color: var(--rio-secondary);
      font-weight: var(--font-extra-bold);
    }

    .GroupStartEndDate {
      font-size: 12px;
      text-align: center;
      color: var(--gray);
      font-weight: var(--font-bold);

      .helpIcon {
        cursor: pointer;
        vertical-align: middle;
        margin-left: 8px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--rio-secondary);
        }
      }
    }

    .DropRegisterBtn {
      min-height: 28px;
    }
  }
}
