#RioButton {
  border: 0px;
  border-radius: 8px;
  text-transform: uppercase;
  min-height: 45px;
  min-width: 150px;
  color: var(--white);
  font-weight: var(--font-bold);
  font-size: 14px;
  letter-spacing: 0.6px;
  transition: none;

  &.small {
    min-height: 30px;
    min-width: 90px;
  }

  &.rounded {
    border-radius: 32px;
    &.primary {
      &:disabled {
        &::after {
          border-radius: 32px;
        }
      }
    }
  }

  &.bordered {
    &.primary {
      background: transparent;
      color: var(--rio-primary);
      border: 2px solid var(--rio-primary);

      &:hover {
        color: var(--white);
      }
    }
  }

  &.primary {
    background: linear-gradient(
      90deg,
      var(--rio-primary),
      var(--rio-primary_variant)
    );

    &:focus,
    &:active,
    &:hover {
      background: var(--rio-primary_hover);
    }

    &:disabled {
      position: relative;
      color: var(--white);

      &:hover {
        background: linear-gradient(
          90deg,
          var(--rio-primary),
          var(--rio-primary_variant)
        );
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: var(--black30);
        border-radius: 8px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus,
  &:active,
  &:hover {
    color: var(--white);
  }
}
