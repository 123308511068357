.SideNavbar {
  .ant-menu.ant-menu-sub.ant-menu-inline {
    background: transparent;
    .ant-menu-item-selected {
      background: transparent;
    }
  }
  .menu-item {
    background-color: black;
    .menu-item-text {
      color: var(--gray);
    }
    &:hover {
      background: var(--rio-background3) !important;
      
      border-left: 3px solid var(--rio-primary);
      .menu-item-text {
        color: var(--rio-secondary);
      }
    }
    &.active {
      background: var(--rio-background3);
      border-left: 3px solid var(--rio-secondary);
      .menu-item-text {
        color: var(--rio-secondary);
      }
    }
  }
  .ant-menu-item::after {
    border-right: 2px solid black;
  }
}