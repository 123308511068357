.RioCardWrapper {
  position: relative;
  box-shadow: 0px 5px 5px var(--black20);
  margin-bottom: 28px;

  .RioCarCardContent {
    display: flex;
    align-items: stretch;
  }

  .RioCarActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--rio-menu);
    padding: 16px 20px;
  }

  .UserActivities {
    display: flex;
    flex-direction: column;
    height: 100%;

    ul {
      background: var(--black);
      padding: 32px 22px 26px 20px;
      margin-bottom: 0px;
      flex: 1;

      li {
        list-style: none;
        padding-left: 20px;
        position: relative;

        &::before {
          content: "";
          display: block;
          height: 15px;
          width: 15px;
          background: var(--gray2);
          border-radius: 50%;
          position: absolute;
          left: -7px;
          top: 7px;
          z-index: 1;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          border-left: 1px solid;
          border-color: var(--gray1);
          top: 7px;
          left: 0px;
        }

        .activityRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 32px;

          .activityType {
            .dateTime {
              opacity: 0.34;
            }
          }
        }

        &:last-child {
          &::after {
            display: none;
          }

          .activityRow {
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  .SelectedCarBar {
    padding: 4px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 8;
    font-size: 12px;
    text-align: center;
    font-weight: var(--font-medium);
    background: var(--rio-primary_hover);
  }
}
