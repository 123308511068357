.RioPage {
  background-color: var(--rio-background);

  &.TopBannerShown {
    .PageContent {
      min-height: calc(100vh - var(--header-height-lg) - 235px);

      @media (max-width: 1600px) {
        min-height: calc(100vh - var(--header-height-md) - 235px);
      }
    }
  }

  .PageContent {
    min-height: calc(100vh - var(--header-height-lg) - 185px);

    @media (max-width: 1600px) {
      min-height: calc(100vh - var(--header-height-md) - 185px);
    }

    @media (max-width: 768px) {
      padding-top: 0px;
    }

    .RioTermsOfService,
    .RioPrivacyPolicy {
      max-width: 1024px;
      margin: 0px auto;
      padding: 40px 24px;
      color: var(--white);
      font-size: 16px;
      line-height: 1.8;
      h1 {
        margin-bottom: 24px;
      }
      h3 {
        margin-bottom: 12px;
      }
      .italic {
        font-style: italic;
      }
      .uppercase {
        text-transform: uppercase;
      }
      ul {
        padding-left: 50px;
      }
      a {
        color: var(--rio-secondary);
      }
    }
  }
}

@media (max-width: 480px) {
  .home_banner
    .footer_banner
    .bottom_footer
    .inner_footer_banner
    .f_items_banner
    .f_item {
    font-size: 15px;
  }
}
.RioDashboardContainer {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  color: var(--white);
  background-color: var(--rio-background);

  .DashboardInner {
    display: flex;
    width: 100%;

    .DashboardContent {
      flex: 1;
      position: relative;
      height: calc(100vh - var(--header-height-lg));
    }

    @media (max-width: 1600px) {
      .DashboardContent {
        height: calc(100vh - var(--header-height-md));
      }
    }
  }

  &.TopBannerShown {
    min-height: calc(100vh - var(--top-banner));

    .DashboardContent {
      height: calc(100vh - var(--header-height-lg) - var(--top-banner));
    }

    @media (max-width: 1600px) {
      .DashboardContent {
        height: calc(100vh - var(--header-height-md) - var(--top-banner));
      }
    }

    .DashboardSideMenu {
      .scrollbar-container {
        max-height: calc(100vh - var(--header-height-lg) - var(--top-banner));
        @media (max-width: 1600px) {
          max-height: calc(100vh - var(--header-height-md) - var(--top-banner));
        }
      }
    }
  }

  @media (max-width: 768px) {
    .DashboardInner {
      padding-top: 0px;
      .DashboardContent {
        height: auto;
      }
    }
  }
}
