.HeaderDesktop {
  height: var(--header-height-lg);
  background: var(--rio-menu);
  box-shadow: 0px 5px 5px var(--black16);
  z-index: 980;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;

  @media (max-width: 1600px) {
    height: var(--header-height-md);
  }

  .HeaderRight,
  .HeaderLeft {
    display: flex;
    align-items: center;

    .HeaderLogo {
      min-width: 100px;
      width: 100px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .PageMenu {
    padding-left: 30px;

    .RioMainMenu {
      display: flex;
      margin: 0px;
      padding: 0px;

      .RioMainMenuItem {
        list-style: none;
        white-space: nowrap;
        line-height: 46px;
        padding: 0 20px;
        text-transform: uppercase;
        color: var(--gray);
        cursor: pointer;

        .SubMenuTriger {
          display: flex;
          align-items: center;

          span {
            margin-right: 12px;
          }
        }

        svg {
          fill: var(--gray);
        }

        a {
          color: var(--gray);
        }

        &:hover,
        &.active {
          font-weight: var(--font-bold);
          color: var(--rio-secondary);
          svg {
            fill: var(--rio-secondary);
          }

          a {
            color: var(--rio-secondary);
          }
        }
      }
    }
  }

  .UserBallaceContainer {
    display: flex;
    align-items: stretch;
    margin-right: 30px;

    > div {
      &:not(.UserBallance) {
        margin-right: 12px;
      }
    }

    .UserBallance {
      background: var(--gray3);
      padding: 6px 14px;
      border-radius: 10px;
      color: var(--gray);
      min-width: 185px;
      max-width: 185px;

      .BallanceRow {
        display: grid;
        gap: 12px;
        align-items: center;
        grid-template-columns: 18px 40px 1fr;
        color: var(--rio-secondary);

        .NetworkIcon {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .NetworkName {
          font-size: 10px;
          white-space: nowrap;
        }

        .NetworkValue {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
          font-size: 14px;
          font-weight: var(--font-bold);
        }
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .UserImage {
    height: 40px;
    width: 40px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    @media (max-width: 1600px) {
      height: 40px;
      width: 40px;
    }
  }
}

.MarketHeaderDesktop {
  display: flex;
  flex-direction: row-reverse;
  background-color: #293336;
  padding: 10px 50px;

  .ant-badge {
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
