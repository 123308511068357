.balance-widget {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #22262d;
  color: var(--white);
  padding: 10px 20px;
  opacity: 0.8;
  cursor: pointer;

  &>.left,
  &>.right {
    text-align: right;
    margin-right: 20px;

    &>div {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;

      &:first-child {
        margin-bottom: 4px;
      }
    }
  }

  &>.right {
    div {
      font-weight: 900;
      max-width: 100px;
    }
  }

  &>.icon {
    width: 25px;
    height: 25px;
  }
}

.wallet {
  border: 0.5px solid var(--gray-400);
  //padding: 10px;
  border-radius: 5px;
  width: calc(50% - 15px);

  .wallet_title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-400);
    padding: 5px 0;
  }

  .wallet_balance {
    font-size: 20px;
    font-weight: 400;
  }

  .wallet_usd_balance {
    font-size: 20px;
    font-weight: 400;
  }

  .wallet_retangle {
    border: none;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 10px;
    }

    .button_bar {
      width: 40%;
    }
  }
}

.item_bar,
.left,
.right {
  &>div {
    font-weight: 500;
    font-size: 16px;
    &>span {
      padding-right: 10px;
    }
  }

  .button_bar {
    font-size: 15px;
    font-weight: 500;
  }
}

.rio-detailCols {
  td>span {
    padding-right: 10px;
  }
}