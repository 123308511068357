.RioLanguageMenu {
  .ant-dropdown-menu-title-content {
    margin-left: 4px;
  }
}

.RioLanguageTriger,
.RioLanguageTrigerWithLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;

  span {
    color: var(--gray);
    margin-left: 16px;
  }
}

.RioLanguageTriger {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    right: -10px;
    left: -10px;
    border: 1px solid;
    border-radius: 50%;
    border-color: var(--rio-primary);
  }
}
