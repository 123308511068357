.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60vh;
  color: white;

  p {
    padding: 0;
    margin: 0;
  }

  .title {
    font-size: 20px;
    font-family: sans-serif;
    font-weight: lighter;
  }

  .desc {
    width: 300px;
    margin-top: 12px;
    margin-bottom: 15px;
    font-size: 15px;
  }

  .btn {
    border-radius: 10px;
    margin-top: 20px;
    border: none;
    color: white;
    font-weight: bold;
  }
}
