.rioMainView {
  height: 100%;
  position: relative;

  .rioPageHeader {
    padding: 30px 30px 24px;

    @media (min-width: 1600px) {
      padding: 45px 30px 30px;
    }

    @media (max-width: 1024px) {
      padding: 20px 22px 30px;
      background: var(--rio-menu);
    }
  }
  .rioSubMenu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
    overflow: auto;
    background: var(--rio-submenu);
    padding: 8px 12px;
    box-shadow: 0px 3px 6px var(--black20);

    .rioSubMenuItem {
      height: 45px;
      justify-content: space-between;
    }

    @media (max-width: 375px) {
      justify-content: space-between;
    }

    &.sticky {
      top: 0;
      position: fixed;
      width: 100%;
      z-index: 1100;
    }
  }
  
  .rioPageContent {
    padding-left: 38px;
    padding-right: 50px;
    padding-bottom: 30px;

    .CarGrid,
    .StationsGrid {
      @media (min-width: 576px) {
        display: grid;
        grid-gap: 20px;
  
        .RioCardWrapper {
          margin-bottom: 5px;
        }
      }
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      }
    }

    @media (max-width: 1440px) {
      padding-left: 32px;
      padding-right: 40px;
    }

    @media (max-width: 1366px) {
      padding-left: 28px;
      padding-right: 32px;
    }

    @media (max-width: 1024px) {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 22px;
      padding-right: 22px;
    }
  }
}
