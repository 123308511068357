.rio-modal {
  .ant-modal {
    padding-bottom: 0px;
    @media (max-width: 575px) {
      max-width: 100vw;
      margin: 0px;
    }
  }

  .ant-modal-body {
    padding: 0px;
    height: 100%;
  }

  .ant-modal-content {
    background: var(--black);
    box-shadow: 0px 3px 20px var(--black30);
    border-radius: 20px;
    height: 100%;
  }

  .rio-modal-header,
  .rio-modal-body,
  .rio-modal-footer {
    position: relative;
    z-index: 1;
  }

  .rio-modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 20px;
    font-weight: 800;
    color: var(--white);

    .close_btn {
      cursor: pointer;
      right: 15px;
      top: 50%;
      display: flex;
      position: absolute;
      transform: translateY(-50%);
      svg {
        fill: var(--gray);

        &:hover {
          fill: var(--white);
        }
      }
    }
  }

  .rio-modal-body {
    overflow: auto;
    padding: 30px 40px;
    max-height: calc(100vh - 200px);
    height: calc(100% - 60px);
    border-radius: 0 0 10px 10px;

    @media (max-width: 575px) {
      padding: 12px 16px;
    }

    .networkInfo {
      color: var(--rio-secondary);
      font-size: 0.85em;
    }
  }

  &.fullScreen {
    .ant-modal-content {
      border-radius: 0px;
    }
  }

  &.bottom {
    .ant-modal-content {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .ant-modal {
      position: absolute;
      left: 0px;
      bottom: 0px;
      top: unset;
    }
  }

  .rio-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    margin-bottom: 2px;

    button {
      margin-right: 12px;
    }
  }
}
