.RioTopBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  height: 0px;
  font-size: 16px;
  color: var(--white);
  font-weight: var(--font-regular);
  transition: height 0.3s ease 0s;
  background: linear-gradient(
      -90deg,
      var(--rio-primary_variant),
      var(--rio-primary),
      var(--rio-primary_variant),
      var(--rio-primary)
    )
    0% 0% / 400% 400%;
  animation: 10s ease 0s infinite normal none running gradientanimation;

  @keyframes gradientanimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &.active {
    height: var(--top-banner);
    visibility: visible;
  }

  .BannerMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 50px);
  }

  .BannerClose {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      fill: var(--white);
      transform: rotate(45deg);
    }
  }

  a {
    margin: 0px 4px;
    color: var(--rio-secondary);
  }
}
