.player-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #040307;
  position: relative;

  .video-card {
    position: relative;
    padding-top: 100%;
    width: 100%;

    & > .react-player__preview {
      position: absolute;
      top: 0;
      pointer-events: none;
    }

    & > video,
    & > .lazy-load-image-background {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  .videoControlButton {
    display: flex;
    position: absolute;
    cursor: pointer;
    bottom: 12px;
    right: 16px;

    svg {
      fill: var(--rio-primary);
    }
  }
}
