.rioIconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: var(--font-bold);
  color: var(--white);
  text-transform: uppercase;
  cursor: pointer;

  span {
    margin-top: 6px;
    line-height: 1;
    white-space: nowrap;
  }

  svg {
    fill: var(--gray);
  }

  &.tabButton {
    text-transform: capitalize;
    font-weight: var(--font-regular);
  }

  &.actived {
    color: var(--rio-secondary);

    svg {
      fill: var(--rio-secondary);
    }
  }

  &.fav {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--rio-menu);
    box-shadow: 0px 3px 6px var(--black75);
  }
  
  &.animation {
    &:hover {
      svg {
        path {
          transition: all 0.1s ease-in-out 0s;
          fill: var(--rio-primary);
        }
      }
    }
  }
}
