@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
	// header height
	--header-height-lg: 120px;
	--header-height-md: 95px;
	--top-banner: 50px;

	// colors are here
	--white: #ffffff;
	--white04: rgb(255 255 255 / 4%);
	--white12: rgb(255 255 255 / 12%);
	--white40: rgb(255 255 255 / 40%);
	--white60: rgb(255 255 255 / 60%);
	--black: #121212;
	--black16: rgb(0 0 0 / 16%);
	--black20: rgb(0 0 0 / 20%);
	--black30: rgb(0 0 0 / 30%);
	--black75: rgb(18 18 18 / 75%);
	--gray: #ababab;
	--gray1: #707070;
	--gray2: #a0a0a0;
	--gray3: #1d2229;
	--gray4: #a1a1a1;
	--gray67: rgb(171 171 171 / 67%);
	--pink: #bb86fc;
	--pink04: rgb(187 134 252 / 4%);
	--pink12: rgb(187 134 252 / 12%);
	--pink40: rgb(187 134 252 / 40%);
	--menu-hover: rgb(98 98 98 / 30%);
	--rio-primary: #d339fa;
	--rio-primary_variant: #5641e7;
	--rio-primary_hover: #5c0e65;
	--rio-secondary: #03dac5;
	--rio-secondary04: rgb(3 218 197 / 4%);
	--rio-secondary12: rgb(3 218 197 / 12%);
	--rio-background: #232228;
    --rio-background2: #272727;
    --rio-background3: #232323;
    --rio-text: #5d5d5d;
	--rio-menu: #2c2b2f;
	--rio-submenu: #2f2f2f;
	--rio-submenu-active: #2f2939;
	--rio-error: #cf6679;
	--rio-success: #03dac5;
    --rio-light: #ffeaea;
	--rio-info: #fff;
	--rio-warn: #f2913e;
	--rio-orange: #ff7b00;
    --rio-red: red; 

	// font
	--font-family: "Open Sans", sans-serif;
	// font weight
	--font-regular: 400;
	--font-medium: 500;
	--font-semi-bold: 600;
	--font-bold: 700;
	--font-extra-bold: 800;
}

body {
	margin: 0;
	font-family: var(--font-family);
	font-weight: var(--font-regular);
	background-color: var(--rio-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0px;
}

input[type="file"] {
	display: none;
}

/* text styles*/
.heading1 {
	font-size: 24px;
	font-weight: var(--font-extra-bold);
	color: var(--white);
}

.heading2 {
	font-size: 18px;
	font-weight: var(--font-bold);
	color: var(--white);
}

.heading3 {
	font-size: 18px;
	font-weight: var(--font-medium);
	color: var(--white);
}

.heading4 {
	font-size: 18px;
	font-weight: var(--font-regular);
	color: var(--white);
}

.subtitle1 {
	font-size: 16px;
	color: var(--white);
	font-weight: var(--font-regular);
}

.subtitle2 {
	font-size: 14px;
	color: var(--white);
	font-weight: var(--font-medium);
}

.subtitle3 {
	font-size: 14px;
	color: var(--white);
	font-weight: var(--font-bold);
}

.body1 {
	font-size: 16px;
	color: var(--white);
}

.body2 {
	font-size: 14px;
	color: var(--white);
}

.caption {
	font-size: 12px;
	color: var(--white);
}

.text-center {
  text-align: center;
}

/* font-sizes */
.fs18 {
	font-size: 18px;
}

/* margins */
.mauto {
    margin: auto;
}

.ma0 {
	margin: 0;
}

.ma10 {
	margin: 10px;
}

.ma20 {
	margin: 20px;
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

.mt20 {
	margin-top: 20px;
}

.mt25 {
	margin-top: 25px;
}

.mt30 {
	margin-top: 30px;
}

.mb5 {
	margin-bottom: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb30 {
	margin-bottom: 30px;
}

.mlauto {
    margin-left: auto;
}

.ml5 {
	margin-left: 5px;
}

.ml12 {
	margin-left: 12px;
}

.ml15 {
	margin-left: 15px;
}

.ml20 {
	margin-left: 20px;
}

.ml30 {
	margin-left: 30px;
}

.mr5 {
	margin-right: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mr15 {
	margin-right: 15px;
}

.mr20 {
	margin-right: 20px;
}

.mr24 {
	margin-right: 24px;
}

.mr30 {
	margin-right: 30px;
}

.mr35 {
	margin-right: 35px;
}

.mr50 {
    margin-right: 50px;
}

.mx15 {
	margin-left: 15px;
	margin-right: 15px;
}

.mlr20 {
	margin-left: 20px;
	margin-right: 20px;
}

.my25 {
	margin-top: 25px;
	margin-bottom: 25px;
}

.my30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* paddings */
.pa0 {
	padding: 0;
}

.pa30 {
    padding: 30px;
}

.pl20 {
	padding-left: 20px;
}

.pl32 {
	padding-left: 32px;
}

.pl40 {
	padding-left: 40px;
}

.pl48 {
	padding-left: 48px;
}

.pr25 {
    padding-right: 25px;
}

.highlight {
	color: var(--rio-secondary);
}

.ant-modal-mask {
	background-color: var(--white12);
	// var(--white12)
}

.modal-btn {
    box-shadow: 0px 3px 6px #000000BF;
}

.list > div {
    background-color: var(--rio-background)
}

/* Text Colors */
.text--grey {
	color: var(--gray);
}

.text--light-grey {
    color: var(--gray1)
}

.linear-gradient-text{
	background: transparent linear-gradient(to bottom, #03dac5, #cb3af9);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: var(--font-extra-bold);
	margin: 0;
	padding: 0;
}

.text--grey1 {
	color: var(--rio-menu);
}

.text--dark {
    color: var(--rio-text);
}

.text--lightgray {
    color: var(--gray);
}

.text--grey5 {
	color: #dfdcdc;
}

.text--orange {
	color: var(--rio-warn);
}

.text--white {
	color: var(--rio-info);
}

.text--error {
	color: var(--rio-error);
}

/* Border Colors */

.border--dark-grey {
    border-color: var(--rio-menu);
}

.border--black {
    border-color: var(--black);
}
.text--light {
    color: var(--rio-light);
}

.text--black {
    color: var(--black);
}

.text--highlight {
    color: var(--rio-success);
}

.text--red {
    color: var(--rio-red);
}

.text--primary {
    color: var(--rio-primary);
}

/* Background Colors */
.bg--dark-grey {
	background: var(--rio-menu);
}

.bg--dark-grey-2 {
    background: var(--rio-submenu);
}

.bg--submenu {
    background: var(--rio-submenu);
}

.br-dark-grey-2 {
    background: var(--rio-background3);
}

.bg--dark-grey-2 {
    background: var(--rio-background3);
}

.bg--dark-grey2 {
	background: #202020;
}

.bg--dark-grey-4 {
	background: var(--gray4);
}

.bg--black {
    background: var(--rio-background);
}

.bg--orange {
	background: var(--rio-orange);
}

.bg-highlight {
    background: var(--rio-secondary)
}

.bg--black-2 {
    background: var(--rio-background2);
}

.bg--black-3 {
    background: var(--black);
}



// visibility
.hidden {
	display: none;
}

// Flex Box
.d-flex {
	display: flex;
}

.space-between {
	justify-content: space-between;
}

.justify-center {
	justify-content: center;
}

.align-center {
	align-items: center;
}

.flex-wrap {
	flex-wrap: wrap;
}
.justify-end {
	justify-content: flex-end;
}

.ant-menu {
	background-color: var(--rio-menu);
}

.ant-menu-horizontal {
	border-bottom: none;
}

.ant-dropdown-menu {
	background-color: var(--rio-submenu);
	border-radius: 4px;
	min-width: 160px;

	.ant-dropdown-menu-item {
		height: 40px;
		margin-bottom: 8px;
		padding: 0px 30px;
		color: var(--gray);

		a {
			color: var(--gray);
		}

		&:hover,
		&.active {
			color: var(--rio-secondary);
			background-color: var(--rio-submenu-active);
			a {
				color: var(--rio-secondary);
			}
		}
	}
}

.ant-menu-submenu-popup {
	> .ant-menu {
		background-color: var(--rio-submenu);
	}
	.ant-menu-item {
		padding: 0px 30px;

		a {
			color: var(--gray);
		}

		&:hover,
		&.active {
			background-color: var(--rio-submenu-active);
			a {
				color: var(--rio-secondary);
			}
		}
	}
}

.ant-popover {
	.ant-popover-inner {
		background-color: var(--rio-submenu);

		.ant-popover-inner-content {
			color: var(--gray);
		}
	}
	.ant-popover-content {
		.ant-popover-arrow {
			border-top-color: var(--rio-submenu);
			border-left-color: var(--rio-submenu);
		}
	}
}

.dk-comingSoon {
	text-align: center;
	padding: 40px 0 15px;
	background: #212227;
	flex: 1 1;
	img {
		width: 100%;
		max-width: 350px;
	}
}

.DisabledTooltipWrapper {
	> * {
		pointer-events: none;
	}
}

.RioPagination {
	margin-top: 36px;
	margin-bottom: 12px;
	text-align: center;

	.ant-pagination-item {
		border-radius: 50%;
		background-color: var(--rio-menu);
		border: 0px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 1px 4px var(--black75);

		a {
			color: var(--gray);
		}

		&:hover,
		&.ant-pagination-item-active {
			background-color: var(--rio-secondary);

			a {
				color: var(--black);
			}
		}
	}

	.ant-pagination-jump-prev,
	.ant-pagination-jump-next {
		.ant-pagination-item-link-icon {
			svg {
				color: var(--rio-secondary);
			}
		}
		.ant-pagination-item-ellipsis {
			color: var(--gray);
		}
	}

	.ant-pagination-prev,
	.ant-pagination-next {
		.ant-pagination-item-link {
			border: 0px;
			border-radius: 50%;
			background-color: var(--rio-menu);
			box-shadow: 0px 1px 4px var(--black75);

			svg {
				fill: var(--rio-primary);
			}
		}

		&.ant-pagination-disabled {
			svg {
				fill: var(--gray);
			}
		}
	}
}

.ant-modal-confirm-centered {
	.ant-modal-content {
		background: var(--rio-submenu);
		border-radius: 20px;
		box-shadow: 0px 3px 20px var(--black30);

		.ant-modal-confirm-title {
			color: var(--white);
			text-align: center;
			padding: 12px 0px;
			font-size: 24px;
			font-weight: var(--font-extra-bold);
		}

		.ant-modal-confirm-content {
			color: var(--white);
			text-align: center;
			padding: 8px 24px;
		}

		.ant-modal-confirm-btns {
			width: 100%;
			display: flex;
			margin-bottom: 12px;
			justify-content: space-evenly;
		}
	}
}

.ant-tooltip {
	.ant-tooltip-inner {
		border-radius: 4px;
		padding: 4px 12px;
		background-color: var(--rio-submenu);
		box-shadow: 0px 3px 6px var(--black75);
	}
	.ant-tooltip-arrow-content {
		background-color: var(--rio-submenu);

		.ant-empty-description {
			color: var(--white);
		}
	}
}

.ant-list-split {
	.ant-list-item-meta-title {
		color: white
	}
	.ant-list-item  {
		border: none;
		color: white;
		&:hover {
			cursor: pointer;
			background: #2E2938;
			color: var(--rio-secondary)
		}
	}
}

.ant-list {
	background-color: var(--rio-submenu);
	box-shadow: 0px 3px 6px var(--black75);

	.ant-list-items {
		.ant-list-item {
			padding: 12px;
			border-bottom: 0px;
			cursor: pointer;

			.ant-list-item-meta {
				align-items: center;
			}

			.ant-list-item-meta-content {
				.ant-list-item-meta-title {
					color: var(--white);
				}
				.ant-list-item-meta-description {
					color: var(--gray);
				}
			}

			&:hover,
			&.active {
				background-color: var(--rio-submenu-active);
				.ant-list-item-meta-content {
					.ant-list-item-meta-title {
						color: var(--rio-secondary);
					}
				}
			}
		}
	}
}

.ant-drawer {
	.ant-drawer-content {
		padding-top: 12px;
		background: #2c2b2f;
	}

	.ant-drawer-header {
		border-bottom: 0px;
		background: var(--rio-submenu);

		.ant-drawer-title {
			color: var(--white);
			font-size: 18px;
			font-weight: var(--font-bold);
		}

		.ant-drawer-close {
			padding: 16px;

			svg {
				fill: var(--gray);
			}
		}
	}
	.ant-drawer-body {
		padding: 12px 0px 0px;
	}
}

/** simple recapture modal */
.ReCaptchModal {
	&.rio-modal {
		.rio-modal-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0px;
			justify-content: space-between;

			.NoRobotImage {
				border-radius: 50%;
				width: 120px;
				height: 120px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
			}
		}
	}
}

.ant-table-wrapper {
    .ant-table {
        background: var(--rio-background);
        color: var(--gray);

        .ant-table-thead {
            .ant-table-cell {
                background: var(--rio-background);
                color: var(--gray);
                border-bottom: none;
            }
        }

        // .ant-table-tbody > tr > td {
        // background: unset;
        // border-bottom: 1px solid var(--gray4);
        // }
        .ant-table-tbody > tr > td {
            background: unset;
            border-bottom: 1px solid rgba(161,161,161, 0.3);

        }
    }
}

.ant-slider {
    .ant-slider-track {
        background: linear-gradient(90deg, rgba(211,57,240,1) 0%, rgba(1,218,197,1) 100%);
    }

    .ant-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -6px;
    }

    .ant-slider-handle-1 {
        border: 2px solid #d339f0;
        background-color: #d339f0;
    }

    .ant-slider-handle-2 {
        border: 2px solid #01dac5;
        background-color: #01dac5;
    }
}

.ant-modal-content {
	border-radius: 5px;
}

.ant-radio .ant-radio-inner {
	background-color: transparent;
}
.ant-radio-checked .ant-radio-inner{
    border-color: #01dac5 !important ;
	background-color: transparent;
}

.ant-radio-checked .ant-radio-inner:after{
    background-color: #01dac5;

}

.ant-radio:hover .ant-radio-inner {
    border-color: #01dac5 ;
	background-color: transparent;
}

/* Scrollbar Styles */
/* width */
::-webkit-scrollbar {
  width: 5px;
	height: 2px;
	margin-top: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent 
}

.grecaptcha-badge { visibility: hidden; }

.web3modal-modal-lightbox {
	z-index: 1000 !important;
}