.RioStationCard {
  position: relative;

  .RioStationCardHead {
    margin-bottom: 6px;
    z-index: 10;
    width: 100%;

    .heading2 {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: normal;

      & > span {
        max-width: calc(100% - 60px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .editButton {
        display: flex;
        background: transparent;
        border-radius: 21px;
        padding: 4px;
        margin-left: 4px;
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
        }

        &:hover {
          transition: all 0.7s;
          background: linear-gradient(
            90deg,
            var(--rio-primary),
            var(--rio-primary_variant)
          );
        }
      }
    }
  }

  .RioStationCardFooter {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    justify-content: space-between;

    .AccruedTokens {
      .subtitle2 {
        &:first-child {
          color: var(--gray);
        }
        &:last-child {
          margin-left: 0.8rem;
          color: var(--rio-secondary);
        }
      }
    }
  }
}
